

import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { DomSanitizer, Meta, SafeHtml, Title } from '@angular/platform-browser';
import { Data, Router } from '@angular/router';
import { RESPONSE } from '@nguniversal/express-engine/tokens';
import { plainToInstance } from 'class-transformer';
import { Metadata } from 'eli-ui-lib';
import { map, Observable } from 'rxjs';
import { ELICHECKMETA, PagePrefix, PageType } from 'src/conf/constant';
import { environment } from 'src/environments/environment';
import { Response } from 'express';
import { SharedDataService } from 'elbuild-ui-lib-core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
@Injectable({
  providedIn: 'root'
})
export class MetaService {

  private static instanceCounter = 0;
  instanceNumber: number;
  sitedescription: any;
  sitecover: any;
  sitetitle: any;
  fbid: any;
  siteURL: string;
  analytics: any;
  sitename: any;

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    @Optional() @Inject(RESPONSE) private response: Response,
    private meta: Meta, private title: Title,
    @Inject(DOCUMENT) private doc: any,
    private router: Router,
    private sanitizer: DomSanitizer,
    private sharedDataService: SharedDataService,
    private translateService: TranslateService,
    private http: HttpClient) {
    if (isPlatformBrowser(this.platformId)) { // FIXME
      this.instanceNumber = MetaService.instanceCounter++;
      if (this.instanceNumber > 0) {
        throw new Error('MetaService must be kept a singleton but more than one instance was created');
      }
    }

    this.sitedescription = environment.sitedescription;
    this.sitecover = environment.sitecover;
    this.sitetitle = environment.sitetitle;
    // this.fbid = data.fbid;
    this.siteURL = environment.siteurl;
    // this.analytics = data.analytics;
    this.sitename = environment.sitename;


  }



  init() {
    // this.setGTagManager();
    this.setTitle(undefined);
  }

  setPageTags(routedata: Data, url: string) {
    // console.log('setPageTags', routedata, url);
    const yfcheck = this.meta.getTag('property="' + ELICHECKMETA + '"');
    if (!yfcheck || yfcheck.content !== url) { // lo fo solo se cambio pagina

      const title = routedata ? routedata['title'] : undefined;
      this.setTitle(title);

      const description = this.sitedescription;
      const image = this.sitecover;
      this.meta.updateTag({ name: 'description', content: description });

      this.meta.updateTag({ property: 'og:description', content: description });
      this.meta.updateTag({ property: 'og:image', content: image });
      this.meta.addTag({ property: ELICHECKMETA, content: url });
      this.meta.updateTag({ name: 'twitter:site', content: this.sitetitle });
      this.meta.updateTag({ property: 'og:site_name', content: this.sitetitle });

      /* if (this.fbid) {
         this.meta.updateTag({ property: 'fb:app_id', content: this.fbid });

         if (isPlatformBrowser(this.platformId)) {
           this.facebookService.viewContent('page', window.location.href);
         }
       }*/

      const pagetype: PageType = routedata?.pagetype;
      const pageprefix: PagePrefix = routedata?.pageprefix;


      this.loadMetadata(url, pagetype, pageprefix);

    }
  }


  loadMetadata(url: string, pagetype: PageType, pageprefix: PagePrefix) {




    if (url?.length) {
      const path = url.split('?')[0].split('#')[0];
      this.getMetadata(path).subscribe((data: Metadata) => {
        if (data && data.is404 && (pagetype === PageType.PRODUCT || pagetype === PageType.TAXONOMY)) {
          if (isPlatformServer(this.platformId)) {
            this.response.status(404);
          }
          this.router.navigate(['/404'], { skipLocationChange: true });

        } else if (data && data.is200) {
          this.updatePageTags(data, url, pageprefix);
          if (pagetype === PageType.PRODUCT) {
            this.addSchemadata(data as Metadata);
          }
        }

      }, error => {
        console.log('tag service error', error);
      });


    }
  }

  updatePageTags(pagetags: Metadata, url: string, pageprefix: PagePrefix) {

    this.setTitle(pagetags.metatitle);
    this.meta.addTag({ property: ELICHECKMETA, content: pagetags.metatitle });
    if (pagetags.metadescription) {
      this.meta.updateTag({ name: 'description', content: pagetags.metadescription });
      this.meta.updateTag({ property: 'og:description', content: pagetags.metadescription });
    }

    this.meta.updateTag({ name: 'twitter:url', content: this.siteURL + pagetags.url });
    this.meta.updateTag({ property: 'og:url', content: this.siteURL + pagetags.url });




    if (pagetags.canonical?.length) {
      this.addCanonicalURL(pagetags.canonical);
    }



    if (pagetags.metaimage) {
      this.meta.updateTag({ name: 'twitter:image', content: pagetags.metaimage });
      this.meta.updateTag({ property: 'og:image', content: pagetags.metaimage });
    }






  }



  addCanonicalURL(url: string) {
    const link: HTMLLinkElement = this.doc.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.doc.head.appendChild(link);
    link.setAttribute('href', url);
  }



  addSchemadata(meta: Metadata) {

    if (!meta) {
      this.sharedDataService.addCommonData('schemadata', undefined);
    } else {



      const metadata = plainToInstance(Metadata, meta);

      const jsondata = this.getSafeHTML(meta.schemadata);

      this.sharedDataService.addCommonData('schemadata', jsondata);
      //this.doc.head.appendChild(jsondata);
    }
  }


  getSafeHTML(jsonLD: { [key: string]: any }): SafeHtml {
    const json = jsonLD ? JSON.stringify(jsonLD, null, 2).replace(/<\/script>/g, '<\\/script>') : '';
    // escape / to prevent script tag in JSON
    const html = `<script type="application/ld+json">${json}</script>`;

    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  setCatalogTitle(cat: string) {


    this.translateService.get('catalogue').subscribe((s: string) => {
      let title: string = s;
      if (cat) {
        if (environment.lang !== 'chrula') {
          title = title + ': ' + cat;
        } else {
          title = cat;
        }
      }

      if (environment.lang === 'chrula') {
        title = title + ': ' + 'Chinese Russian Latin'
      }
      // vedi https://gitlab.elbuild.com/eli/eli-tickets/-/issues/39
      this.setTitle(title);
    })

  }

  private setTitle(page) {
    let title = this.sitename;


    if (page) {
      title = page + ' | ' + this.sitename;
    }
    this.title.setTitle(title);
    this.meta.updateTag({ property: 'og:title', content: title });
    this.meta.updateTag({ name: 'twitter:title', content: title });
    this.meta.updateTag({ name: 'title', content: title });
  }

  private getMetadata(url: string): Observable<any> {

    let base64;

    if (isPlatformBrowser(this.platformId)) {
      base64 = btoa(url);
    } else {
      const encode = (str: string): string => Buffer.from(str, 'binary').toString('base64');
      base64 = encode(url);
    }
    return this.http.get(`/ws/ssr/path/${base64}`)
      .pipe(map(res => {

        if (!res) return undefined;
        const metadata: Metadata = plainToInstance(Metadata, res);
        metadata.url = url;
        return metadata;
      }
      )
      );
  }




}
